<template>
  <div :class="{ item: true, highlight: isReserved }" @click="$emit('click')">
    <div
      v-if="[1, 3].includes(getState(ichiban))"
      class="overlay"
      @click.stop=""
    >
      <div class="overlay__msg">{{ statusMap[getState(ichiban)] }}</div>
    </div>
    <div class="item__image">
      <v-chip v-if="isReserved" class="item__image__hint" color="#2fd5d8">
        保留中
      </v-chip>
      <v-img
        aspect-ratio="1.5"
        width="100%"
        min-height="200"
        :src="
          ichiban.previewImageUrl ||
          `https://fakeimg.pl/350x200/?text=暫無預覽圖`
        "
        cover
      ></v-img>
    </div>
    <div>
      <div
        v-if="like"
        @click.stop="$emit('unlike', $event)"
        class="d-flex align-items-center mt-1 mb-0 fit-width"
      >
        <v-icon color="pink" class="like-btn like-btn--liked">
          mdi-heart
        </v-icon>
        <span class="ml-2">已加入我的最愛</span>
      </div>
      <div
        v-else
        @click.stop="$emit('like', $event)"
        class="d-flex align-items-center mt-1 mb-0 fit-width"
      >
        <v-icon color="pink" class="like-btn"> mdi-heart-outline </v-icon>
        <span class="ml-2">加入我的最愛</span>
      </div>
    </div>
    <div class="d-flex justify-space-between py-1">
      <div>{{ `${ichiban.name}` }}</div>
      <div class="yellow--text">
        <span class="vertical-middle">{{ `（${ichiban.count}抽）` }} </span>
        <v-img
          width="15px"
          height="15px"
          src="@/assets/image/coin.png"
          alt="點數"
          contain
          class="d-inline-block vertical-middle"
        />
        <span class="vertical-middle">
          {{ ichiban.plans.find((plan) => plan.count === 1).amount }}
        </span>
      </div>
    </div>
    <div v-if="[0, 2, 3].includes(getState(ichiban))" class="text-left">
      <div>
        <v-chip
          v-for="(reward, index) in getRemainReward(ichiban)"
          :key="index"
          :color="'#d4af37'"
          text-color="black"
          label
          small
          pill
          class="mr-1"
        >
          {{ reward.reward }}/1
        </v-chip>
      </div>
      <div class="caption text-right">
        獎品數/剩餘抽數
        {{
          ` ${getRemainReward(ichiban).length}/${ichiban.count - ichiban.sold}`
        }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  name: "",
  props: ["ichiban", "like", "keepingIchibans"],
  data: () => ({
    statusMap: { 1: "完售", 2: "保留", 3: "鎖定" },
  }),
  computed: {
    ...mapState("clientModule", {
      userID: (state) => state.userID,
      UID: (state) => state.UID,
      user: (state) => state.user,
      token: (state) => state.token,
      store: (state) => state.store,
    }),
    isReserved() {
      return [2].includes(this.getState(this.ichiban));
    },
  },
  methods: {
    // state - 1 完售、2 保留（當前使用者可抽）、3 鎖定（當前使用者不可抽）、0 正常
    getState(ichiban) {
      if (
        ichiban.count === ichiban.sold ||
        ichiban.rewards.every((reward) => !!reward.hit)
      ) {
        return 1;
      }
      if (
        this.keepingIchibans.map((ichiban) => ichiban._id).includes(ichiban._id)
      ) {
        return 2;
      }
      if (ichiban.lockTo && !dayjs().isAfter(ichiban.lockTo)) {
        // if (ichiban.lockToUser === this.userID) {
        //   return 2;
        // }
        return 3;
      }
      return 0;
    },
    getRemainReward(ichiban) {
      return ichiban.rewards.filter((reward) => !reward.hit);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  max-width: 350px;
  margin: 10px 5px;
  padding: 10px;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  background-color: #172c3b;
  cursor: pointer;

  // &__body {
  //   display: flex;
  //   justify-content: space-between;
  // }

  &__image {
    position: relative;

    &__hint {
      position: absolute;
      z-index: 2;
      right: 10px;
      top: 10px;
    }
  }
}

.like-btn {
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
}

$theme-color: #2fd5d8;
.highlight {
  border-style: solid;
  // border-color: rgb(20, 231, 198);
  border-color: $theme-color;
  box-shadow: 0 0 0.25em $theme-color, inset 0 0 0.25em $theme-color;
  outline: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  &__msg {
    color: white;
    font-size: 28px;
    font-weight: bold;
    margin: 0;
  }
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
