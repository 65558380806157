<template>
  <div @click="$emit('click')" class="item text-center grid-item">
    <div class="grid-item__inner">
      <div class="overlay"
        v-if="getState(ichiban) === 2"
      >
        <div class="overlay__msg">保留中</div>
      </div>
      <div
        v-else-if="getState(ichiban)"
        class="overlay" @click.stop="">
        <div class="overlay__msg">{{ statusMap[getState(ichiban)] }}</div>
      </div>
      <img
          width="90%"
          height="90%"
          :src="
            ichiban.gridPreviewImageUrl ||
            `https://fakeimg.pl/350x200/?text=Hello`
          ">
        </img>
        <v-icon
          v-if="like"
          @click.stop="$emit('unlike', $event)"
          color="pink"
          class="like-btn like-btn--liked"
        >
          mdi-heart
        </v-icon>
        <v-icon
          v-else
          @click.stop="$emit('like', $event)"
          color="pink"
          class="like-btn"
        >
          mdi-heart-outline
        </v-icon>
        <div class="pt-1 text mx-auto">
          <div>{{ ichiban.name }}-{{ ichiban.count }} 抽</div>
        </div>
      </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from "vuex";
import Timer from '@/components/client/Timer.vue'

export default {
  name: "IchibanGridCard",
  props: ["ichiban", "like","keepingIchibans"],
  components:{ Timer },
  data: () => ({
    statusMap: {1:'完售', 2:'保留', 3: '鎖定'}
  }),
  computed: {
    ...mapState("clientModule", {
      userID: (state) => state.userID,
      UID: (state) => state.UID,
      user: (state) => state.user,
      token: (state) => state.token,
      store: (state) => state.store,
    }),
  },
  methods: {
    getState(ichiban){
      if(ichiban.count === ichiban.sold || ichiban.rewards.every((reward) => !!reward.hit)){
        return 1
      }
       if (
        this.keepingIchibans.map((ichiban) => ichiban._id).includes(ichiban._id)
      ) {
        return 2;
      }
      if(ichiban.lockTo && !dayjs().isAfter(ichiban.lockTo)){
        // if(ichiban.lockToUser === this.userID){
        //   return 2
        // }
        return 3
      }
      return 0;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>

.item {
  width: 100%;
  padding: 10px;
  font-weight: 500;
  font-size: 20px;
  background-color: #172c3b;
  cursor: pointer;

  &.grid-item {
    width: 100%;
    aspect-ratio: 1;
    background-image: url(~@/assets/frame-grid-cut.png);
    background-size: 100% 100%;
    background-position: center;
    background-color: transparent;
    position: relative;

    .text {
      display: block;
      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;

      font-size: 18px;
      text-shadow: 0 0 10px rgba(128, 0, 128, 1);

      @media (min-width: 600px) {
        font-size: 22px;
      }
    }
  }

  .grid-item__inner {
    width: 90%;
    height: 90%;

    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateX(50%) translateY(-45%);

    img{
      object-fit: cover;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  &__msg {
    color: white;
    font-size: 28px;
    font-weight: bold;
    margin: 0;
  }
}

.like-btn {
  position: absolute;
  top: 5px;
  right: 15px;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
}
</style>
